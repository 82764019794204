<template>

    <div id="projects" v-if="subscriptionMeta.error">
        <error-placeholder/>
    </div>
    <div v-else>
        <subscription-expiration :with-popin="true"/>
        <div v-if="!projectListLoaded">
            <div class="v-application text-center" style="top: 50%; position: fixed; left: 50%">
                <v-progress-circular indeterminate color="primary"/>
            </div>
        </div>
        <v-row v-else>
            <v-col :cols="12">
                <div class="v-application">
                    <h4 class="text-h4 font-weight-black primary--text mb-0 ">{{ $t(`navigationMenu_link_title_${this.$route.name}`) }}</h4>
                </div>
                <project-list :has-project="(projectList.data && projectList.data.length>0)" ref="projectsList"/>
                <onboarding-first-project @createProject="openCreateProject" v-if="!projectList.data || projectList.data.length === 0"/>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import SubscriptionExpiration from "@/components/yooda-components/SubscriptionExpiration.vue";
import ProjectList from "@/components/yooda-components/project/ProjectList.vue";
import ErrorPlaceholder from "@/components/yooda-components/placeholder/ErrorPlaceholder.vue";
import apiCalls from "@/api/axios/configuration/project";
import OnboardingFirstProject from "@/components/yooda-components/onboarding-helper/OnboardingFirstProject.vue";
import {mapGetters} from "vuex";


export default {
    data() {
        return {
            projectList:null,
            projectListLoaded:false
        }
    },
    components: {
        ErrorPlaceholder,
        SubscriptionExpiration,
        ProjectList,
        OnboardingFirstProject
    },
    computed:{
        ...mapGetters({
            activeSubscription:"customersManager/getActiveSubscription"
        }),
    },
    methods:{
        initComponentData(){
            this.resetCurrentProject()

            this.getComponentData({
                componentName:'projectList',
                promise: apiCalls.getProjects(this.$route.params.subscriptionId, false)
            }).then(result=>{
                if(result){
                    this.projectListLoaded = true
                    if(this.projectList.data && this.projectList.data.length === 0){
                        if(!localStorage.getItem('first-project-wizard')){
                            localStorage.setItem('first-project-wizard','true')
                            if(this.activeSubscription().state === 'active' || this.activeSubscription().state === 'trialing'){
                                this.$router.push({ name: 'first_project', params: { subscriptionId: this.$route.params.subscriptionId } })
                            }
                        }
                    }
                }
            })
        },
        openCreateProject(){
            this.$refs.projectsList.openCreateProject()
        }
    },
    beforeMount() {
        this.initComponentData()
    },
    mounted() {
        this.analyticsIdentify()
    }
}

</script>
