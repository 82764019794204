<template>
    <div style="min-height: 720px">
        <h3 class="text-h4 font-weight-bold mb-2 mt-2">{{ $t('component_projectCreationWizard_stepLocation_title') }}</h3>
        <p class="mb-6">{{ $t('component_projectCreationWizard_stepLocation_subtitle') }}</p>


        <p> {{ $t('component_projectCreationWizard_stepLocation_selectCountry_label') }}</p>
        <v-select
            :items="searchEngines.data"
            v-model="selectedSearchEngine"
            :label="$t('component_projectCreationWizard_stepLocation_countrySelect_label')"
            item-text="name"
            item-value="id"
            dense
            :return-object="true"
            hide-details
            outlined
            @change="onSelectSearchEngine"
            class="rounded-lg"
            style="max-width: 360px; margin: auto"
            v-if="searchEngines.data && searchEngines.data.length>0"
        >
            <template v-slot:selection="{ item, index }">
                <img :src="getCountryFlagBySearchEngine(item.name)" style="width: 18px; margin-right: 8px">
                {{ item.displayedName }}
            </template>

            <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" >
                    <v-list-item-content>
                        <v-list-item-title>
                            <img :src="getCountryFlagBySearchEngine(item.name)" style="width: 18px; margin-right: 8px">
                            <span>{{ item.displayedName }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-select>



        <div v-if="this.getProjectCreationTmp.searchEngine && this.getProjectCreationTmp.searchEngine.languages.length>0">
            <v-icon x-large color="grey lighten-1" class="mt-6">mdi-arrow-down-thin</v-icon>
            <p class="mt-2">{{ $t('component_projectCreationWizard_stepLocation_selectLanguage_label') }}</p>

            <v-select
                :items="this.getProjectCreationTmp.searchEngine.languages"
                v-model="selectedLanguage"
                :label="$t('component_projectCreationWizard_stepLocation_languageSelect_label')"
                item-text="code"
                item-value="id"
                hide-details
                dense
                class="rounded-lg"
                :return-object="true"
                outlined
                @change="onSelectedLanguage"
                style="max-width: 360px; margin: auto"
            >
                <template v-slot:selection="{ item, index }">
                    {{ $t(`common_language_label_${item.code}`) }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" >
                        <v-list-item-content>
                            <v-list-item-title>
                                <span>{{ $t(`common_language_label_${item.code}`) }}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>
        </div>

        <div v-if="this.getProjectCreationTmp.language && (this.getProjectCreationTmp.searchEngine.searchGeolocations && this.getProjectCreationTmp.searchEngine.searchGeolocations.length>0)">
            <div style="width: 450px; margin: 0 auto">
                <v-icon x-large color="grey lighten-1" class="mt-6">mdi-arrow-down-thin</v-icon>
                <div class="mt-2">
                    <span>{{ $t('component_projectCreationWizard_stepLocation_locationTips') }}</span>
                    <div style="opacity: 0.6; display: inline; vertical-align: middle">
                        <information-tooltip
                            :title="$t('component_projectCreationWizard_stepLocation_location_tooltipTitle')"
                            :text="$t('component_projectCreationWizard_stepLocation_location_tooltipText')"
                            :tooltip-width="380"
                        />
                    </div>
                </div>
            </div>
            <div style="position: relative; width: 360px; margin: 32px auto">
                <v-autocomplete
                    :items="displayedLocationList"
                    :label="$t('component_projectCreationWizard_stepLocation_citySelect_label')"
                    item-text="canonicalName"
                    item-value="id"
                    hide-details
                    :return-object="true"
                    @change="onSelectLocation"
                    ref="selectLocation"
                    class="rounded-lg"
                    :disabled="isLocationQuotaMax || isOverQuota || isFullQuota"
                    dense
                    outlined
                />
                <span
                    style="position:absolute; right:50px; top: 12px; background-color: #fff; padding-left: 5px"
                    :class="{
                            'orange--text':isLocationQuotaMax,
                            'red--text':isLocationQuotaMin,
                            'green--text':!isLocationQuotaMax && !isLocationQuotaMin
                        }"
                >
                        {{ `${this.getProjectCreationTmp.locations.length}/${this.maxLocation}` }}
                    </span>
            </div>
            <div style="width: 800px; margin: 0 auto; text-align: left">
                <div class="rounded-lg grey lighten-4 pa-4" style="border: 1px dashed #d0d0d0 !important;min-height: 66px">
                    <v-chip
                        v-for="location in this.getProjectCreationTmp.locations"
                        :key="location.id"
                        class="ma-1"
                        small
                        close
                        :color="isOverQuota ? 'red' : 'primary'"
                        close-icon="mdi-minus-circle"
                        outlined
                        style="background-color: #fff !important;"
                        @click:close="onRemoveLocation(location)"
                    >
                        <v-icon small left>mdi-map-marker</v-icon>{{ (location.targetType === 'country' ? $t(`common_allCountry_label_${location.name.toLowerCase()}`) : location.name) }}
                    </v-chip>
                </div>
            </div>
        </div>

        <div
            class="mt-5 mx-auto"
            style="max-width: 440px"
        >
            <div v-if="isOverQuota">
                <p class="text-body-2 mb-0 rounded-xl py-1" :class="['red lighten-5', 'red--text']">
                    {{ $t('component_projectCreationWizard_stepLocation_error_overQuota') }}
                </p>
            </div>
            <div v-else-if="isFullQuota">
                <p class="text-body-2 mb-0 rounded-xl py-1" :class="['orange lighten-5', 'orange--text']">
                    {{ $t('component_projectCreationWizard_stepLocation_error_fullQuota') }}
                </p>
            </div>
            <div v-else-if="getProjectCreationTmp.locations.length === maxLocation">
                <p class="text-body-2 mb-0 rounded-xl py-1" :class="['orange lighten-5', 'orange--text']">
                    {{ $t('component_projectCreationWizard_stepLocation_error_maxCityQuota') }}
                </p>
            </div>
            <div v-else-if="(getProjectCreationTmp.locations.length < minLocation) && getProjectCreationTmp.language">
                <p class="text-body-2 mb-0 rounded-xl py-1" :class="['orange lighten-5', 'orange--text']">
                    {{ $t('component_projectCreationWizard_stepLocation_error_minCityQuota') }}
                </p>
            </div>
        </div>


    </div>
</template>

<script>

import apiCalls from "@/api/axios/configuration/project";
import LabelMessage from "@/components/yooda-components/LabelMessage.vue";
import InformationTooltip from "@/components/yooda-components/InformationTooltip.vue";
import {mapGetters} from "vuex";

export default {
    name: "ProjectCreationWizardStepLocation",
    data(){
        return{
            searchEngines:[],
            selectedSearchEngine:null,
            selectedLanguage:null,
            maxLocation:5,
            minLocation:1,
        }
    },
    components:{
        LabelMessage,
        InformationTooltip,
    },
    computed:{
        ...mapGetters({
            maxRequestCount:"customersManager/getMaxRequestCount",
            additionalRequestCount:"customersManager/getAdditionalRequestCount",
        }),

        displayedLocationList(){
            return this.getProjectCreationTmp.searchEngine.searchGeolocations.filter(data=>{
                return this.getProjectCreationTmp.locations.filter(tmp=>{ return tmp.id === data.id }).length === 0 ? data : null
            })
        },

        isLocationQuotaMax(){
            return this.getProjectCreationTmp.locations.length >= this.maxLocation
        },
        isLocationQuotaMin(){
            return this.getProjectCreationTmp.locations.length < this.minLocation
        },
        isOverQuota(){
            return (this.getProjectCreationTmp.locations.length * this.getProjectCreationTmp.keywords.length) > this.maxKeyword
        },

        isFullQuota(){
            return (this.getProjectCreationTmp.keywords.length * this.getProjectCreationTmp.locations.length) === this.maxKeyword
        },
        maxKeyword(){
            return  (this.maxRequestCount()+this.additionalRequestCount()) - (this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctKeywordCount*project.distinctSearchGeolocationCount),0) : 0)
        },
    },
    methods:{
        onSelectSearchEngine(searchEngine){
            this.setProjectCreationTmpValue('searchEngine',searchEngine)

            this.selectedLanguage = null
            if(searchEngine.languages.length === 1){
                this.selectedLanguage = searchEngine.languages[0]
                this.$emit('locationIsValid')
            }else{
                this.$emit('locationIsInvalid')
            }

            apiCalls.getSearchEngineGeolocs(searchEngine.id).then((res)=>{
                searchEngine.searchGeolocations = res.content

              searchEngine.searchGeolocations.sort((a,b)=>{
                return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0
              })

                this.setProjectCreationTmpValue('language',this.selectedLanguage)

                let selectedLocationListTmp = []
                selectedLocationListTmp.push(searchEngine.searchGeolocations.find(el => el.targetType === "country"))

                this.setProjectCreationTmpValue('locations',selectedLocationListTmp)
            })


        },

        onSelectedLanguage(language){
            this.setProjectCreationTmpValue('language',language)
            if(this.getProjectCreationTmp.locations.length>0) {
                this.$emit('locationIsValid')
            }
        },

        onSelectLocation(location){
            this.$refs.selectLocation.blur()
            this.$emit('locationIsValid')
            if(this.getProjectCreationTmp.locations.length < this.maxLocation){
                let selectedLocationListTmp = this.getProjectCreationTmp.locations
                selectedLocationListTmp.push(location)
                this.setProjectCreationTmpValue('locations',selectedLocationListTmp)
            }

        },
        onRemoveLocation (item) {
            let findedIndex = -1
            let selectedLocationListTmp = this.getProjectCreationTmp.locations
            selectedLocationListTmp.forEach((data, index)=>{
                if(data.id === item.id){ findedIndex = index }
            })
            selectedLocationListTmp.splice(findedIndex, 1)
            this.setProjectCreationTmpValue('locations',selectedLocationListTmp)

            if (this.getProjectCreationTmp.locations.length < this.minLocation) {
                this.$emit('locationIsInvalid')
            }
        },
        sortSearchEngine (jsonData) {
          let searchEngineList = jsonData.content.map((data)=>{

            return {
              countryCode: data.countryCode,
              id: data.id,
              languages: data.languages,
              name: data.name,
              url: data.url,
              displayedName: this.$t(`common_countryName_for_searchEngine_${data.name}`)
            }
          })
          searchEngineList.sort((a,b)=>{
                return (a.displayedName < b.displayedName) ? -1 : (a.displayedName > b.displayedName) ? 1 : 0
            })

          let frIndex = searchEngineList.findIndex((data)=>{ return data.countryCode === 'fr' })
          let savedSearchEngineFr = searchEngineList[frIndex]
          delete(searchEngineList[frIndex])
          searchEngineList.unshift(savedSearchEngineFr)

          return  searchEngineList
        }
    },
    beforeMount() {
        this.getComponentData( {
            componentName:'searchEngines',
            promise: apiCalls.getSearchEngines(),
            dataFormatter: this.sortSearchEngine
        })
    },
    mounted() {
        let projectTmp = JSON.parse(localStorage.getItem('projectWizardTmp'))
        if(projectTmp){
            if(projectTmp.searchEngine){
                this.selectedSearchEngine = projectTmp.searchEngine
            }
            if(projectTmp.language){
                this.selectedLanguage = projectTmp.language
            }
        }
    }
}
</script>

<style scoped>

</style>
