<template>
    <div>
        <div v-if="suggestLoader" style="background-color: rgba(211,211,211,0.37); width: 100%;z-index: 99; height: 100%; position: absolute;">
            <div class="v-application text-center" style=";top: 50%; position: fixed; left: 50%">
                <v-progress-circular indeterminate color="primary"/>
            </div>
        </div>
        <h3 class="text-h4 font-weight-bold mb-2 mt-4">{{ $t('component_projectCreationWizard_stepCompetitors_title') }}</h3>
        <p v-html="$t('component_projectCreationWizard_stepCompetitors_subtitle')"/>
        <v-row class="ma-0">
            <v-col :cols="12">
                <v-text-field
                    v-model="inputCompetitor"
                    :placeholder="$t('component_projectCreationWizard_stepCompetitors_inputPlaceholder')"
                    dense
                    outlined
                    hide-details
                    style="max-width: 600px"
                    class="rounded-lg mx-auto"
                    @keyup.enter="addCompetitor"
                    :disabled="getProjectCreationTmp.competitors.length >=10"
                >
                    <template v-slot:append>
                        <v-btn
                            :color="getProjectCreationTmp.competitors.length >=10 ? 'grey lighten-2': 'primary'"
                            class="rounded-lg"
                            style="position: absolute;right: 0; top: 1px;min-width: 40px; font-size:20px; height: 38px;padding: 0; box-shadow: none; border-top-left-radius: 0 !important; border-bottom-left-radius: 0!important;"
                            @click="addCompetitor"
                        >
                            +
                        </v-btn>
                    </template>

                </v-text-field>
            </v-col>

            <v-col :cols="12">
                <div class="rounded-lg grey lighten-4 mt-2 mx-auto text-left px-2 pt-2"  style="height:130px; border: 1px dashed #d0d0d0 !important;" >
                    <v-row class="mb-2">
                        <v-col :cols="6" class="text-left">
                            <span class="caption primary--text" v-html="$t('component_projectCreationWizard_stepCompetitors_competitorRemaining').replace('[*COMPETITOR-COUNT*]',this.getProjectCreationTmp.competitors.length)"></span>
                        </v-col>
                        <v-col :cols="6" class="text-right">
                            <span class="caption primary--text" style="cursor: pointer" @click="clearCompetitors">{{ $t('component_projectCreationWizard_stepCompetitors_clearList') }}<v-icon small color="primary" class="ml-2">mdi-backspace-outline</v-icon></span>
                        </v-col>
                    </v-row>

                    <v-chip
                        v-for="competitor in this.getProjectCreationTmp.competitors"
                        :key="competitor"
                        class="ma-1"
                        small
                        close
                        color="primary"
                        close-icon="mdi-minus-circle"
                        outlined
                        style="background-color: #fff !important;"
                        @click:close="removeCompetitor(competitor)"
                    >
                        {{ competitor }}
                    </v-chip>
                </div>
            </v-col>
            <v-col :cols="12" class="text-right">
                <v-btn
                    outlined
                    color="primary"
                    @click="suggestCompetitor"
                    :disabled="disableSuggestKeyword || getProjectCreationTmp.competitors.length >=10"
                >
                    <v-icon style="margin-left: -10px; margin-right: 10px">mdi-auto-fix</v-icon>
                   {{ $t('component_projectCreationWizard_stepCompetitors_suggestCompetitor') }}
                </v-btn>

            </v-col>
            <v-col :cols="12">
                <div style="min-height: 26px; margin-top: -52px">
                    <label-message ref="competitorLabelMessage"  style="max-width: 600px; margin: 0 auto"/>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import LabelMessage from "@/components/yooda-components/LabelMessage.vue";
import apiCalls from "@/api/axios/configuration/project";

export default {
    name: "ProjectCreationWizardStepCompetitors",
    data(){
        return{
            inputCompetitor:null,
            suggestLoader:false,
            disableSuggestKeyword:false
        }
    },
    components:{
        LabelMessage
    },
    methods:{
        addCompetitor(){
            this.$refs.competitorLabelMessage.hideLabelMessage()
            this.explodeStringToArray(this.inputCompetitor).forEach((value)=>{
                if(value.trim() !== ''){
                    let domainName = this.getDomainNameFromString(value)
                    if(domainName){
                        if(domainName.trim().length <= 255){
                            domainName = domainName.toLowerCase()
                            if(this.getProjectCreationTmp.competitors.length <10){
                                let alreadyAdded = false
                                if(domainName.trim() === this.getProjectCreationTmp.websiteUrl){
                                    alreadyAdded = true
                                    this.$refs.competitorLabelMessage.showLabelMessage(this.$t('component_projectCreationWizard_stepCompetitors_error_competitorCantBeWebsite'))
                                }
                                else{
                                    this.getProjectCreationTmp.competitors.forEach(alreadyAddedCompetitor=>{
                                        if(alreadyAddedCompetitor === domainName.trim() ){
                                            alreadyAdded = true
                                            this.$refs.competitorLabelMessage.showLabelMessage(this.$t('component_projectCreationWizard_stepCompetitors_error_alreadyExist'))
                                        }
                                    })
                                }

                                if(!alreadyAdded){
                                    let competitorsTmp = this.getProjectCreationTmp.competitors
                                    competitorsTmp.push(domainName.trim())
                                    this.setProjectCreationTmpValue('competitors',competitorsTmp)
                                }
                            }
                            else{
                                this.$refs.competitorLabelMessage.showLabelMessage(this.$t('component_projectCreationWizard_stepCompetitors_error_competitorMaxError'),'orange lighten-5', 'orange--text')
                            }
                        }
                    }
                    else{
                        this.$refs.competitorLabelMessage.showLabelMessage(this.$t('component_projectCreationWizard_stepCompetitors_error_webSiteBadUrlFormat'))
                    }
                }
            })
            this.$emit('competitorIsValid')
            this.inputCompetitor = null
        },
        removeCompetitor (item) {
            let competitorsTmp = this.getProjectCreationTmp.competitors
            competitorsTmp.splice(competitorsTmp.indexOf(item), 1)
            this.setProjectCreationTmpValue('competitors',competitorsTmp)

            if(competitorsTmp.length === 0){ this.$emit('competitorIsInvalid') }
        },
        clearCompetitors(){
            this.$refs.competitorLabelMessage.hideLabelMessage()
            this.setProjectCreationTmpValue('competitors',[])
            this.$emit('competitorIsInvalid')
        },
        suggestCompetitor(){
            this.$refs.competitorLabelMessage.hideLabelMessage()

            if(this.getProjectCreationTmp.websiteUrl){

                if(this.getProjectCreationTmp.searchEngine && this.getProjectCreationTmp.language){
                    this.suggestLoader = true

                    const searchGeolocation = this.getProjectCreationTmp.searchEngine.searchGeolocations.find(searchGeolocation => {
                        return searchGeolocation.targetType === "country"
                    })
                    apiCalls.getSuggestedCompetitorByDomainName(this.getProjectCreationTmp.websiteUrl, searchGeolocation.criteriaId, this.getProjectCreationTmp.language.code).then((result)=>{
                        if(result.content){
                            let newCompetitorList = this.getProjectCreationTmp.competitors.concat(result.content.map(data=>{ return data.domainName}))
                            this.setProjectCreationTmpValue('competitors',newCompetitorList.slice(0,10))
                        }
                        this.disableSuggestKeyword = true
                    }).catch(()=>{
                        this.$refs.competitorLabelMessage.showLabelMessage(this.$t('component_projectCreationWizard_stepCompetitors_error_competitorSuggested'),'orange lighten-5', 'orange--text')
                    }).finally(()=>{
                        this.suggestLoader = false
                    })
                }else {
                    this.$refs.competitorLabelMessage.showLabelMessage(this.$t('component_projectCreationWizard_stepKeywords_error_searchEngine'),'orange lighten-5', 'orange--text')
                }

            }else {
                this.$refs.competitorLabelMessage.showLabelMessage(this.$t('component_projectCreationWizard_stepCompetitors_error_webSiteBadUrlFormat'),'orange lighten-5', 'orange--text')
            }

        },
    },

}
</script>

<style scoped>

</style>
