<template>


    <v-dialog v-model="firstLoad">
        <v-card class="v-application">
            <v-card-text class="pt-4 pb-8 px-12">
                <h3 class="text-h4 mt-6 mb-4 text-center font-weight-bold">{{ $t('component_onboardingFirstProject_title') }}</h3>
                <div class="text-center">
                    <img src="@/assets/images/onboarding_first_project.svg" alt="first_project" style="width: 100%; max-width: 480px">
                </div>
                <p class="primary--text text-center text-subtitle-1">{{ $t('component_onboardingFirstProject_text_trial') }}</p>
                <p class="text-subtitle-1 font-weight-bold mt-8">{{ $t('component_onboardingFirstProject_text_todo') }}</p>
                <ul style="list-style: auto; padding-left: 16px">
                    <li class="text-subtitle-1">{{ $t('component_onboardingFirstProject_text_todo_1') }}</li>
                    <li class="text-subtitle-1 my-3">{{ $t('component_onboardingFirstProject_text_todo_2') }}</li>
                    <li class="text-subtitle-1">{{ $t('component_onboardingFirstProject_text_todo_3') }}</li>
                </ul>
                <div class="mt-8 text-center">
                    <v-btn color="primary" @click="validCreate">{{ $t('component_onboardingFirstProject_btn_create') }}</v-btn>
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>

</template>

<script>
export default {
    name: "OnboardingFirstProject",
    data() {
        return {
            firstLoad:false,
        }
    },
    methods:{
        validCreate(){
            this.firstLoad = false
            this.$emit('createProject')
        }
    },
    beforeMount() {
        if(!localStorage.getItem('onboardingFirstProject')){
            localStorage.setItem('onboardingFirstProject','true')
            this.firstLoad = true
        }
    }
}
</script>

<style scoped>

</style>
