<template>
    <div>
        <div v-if="suggestLoader" style="background-color: rgba(211,211,211,0.37); width: 100%;z-index: 99; height: 100%; position: absolute;">
            <div class="v-application text-center" style=";top: 50%; position: fixed; left: 50%">
                <v-progress-circular indeterminate color="primary"/>
            </div>
        </div>

        <h3 class="text-h4 font-weight-bold mb-2 mt-0">{{ $t('component_projectCreationWizard_stepKeywords_title') }}</h3>
        <p>{{ $t('component_projectCreationWizard_stepKeywords_subtitle') }}</p>
        <v-row class="ma-0">
            <v-col :cols="8">
                <v-textarea
                    v-model="inputAddKeywords"
                    :placeholder="$t('component_projectCreationWizard_stepKeywords_addPlaceholder')"
                    dense
                    rows="1"
                    :disabled="isFullQuota || isOverQuota"
                    outlined
                    hide-details
                    class="rounded-lg"
                    @keyup.enter="addKeywords"
                    @paste="pasteKeyword"
                >
                    <template v-slot:append>
                        <v-btn :color="(isFullQuota || isOverQuota) ? 'grey lighten-2' : 'primary'" class="rounded-lg" style="position: absolute;right: 0; top: 1px;min-width: 40px; font-size:20px; height: 38px;padding: 0; box-shadow: none; border-top-left-radius: 0 !important; border-bottom-left-radius: 0!important;" @click="addKeywords">+</v-btn>
                    </template>

                </v-textarea>
            </v-col>
            <v-col :cols="4" class="text-right">
                <import-csv-keyword :save-mode="false" @csvImported="onImportCsv"/>
            </v-col>
            <v-col :cols="12">
                <div class="rounded-lg grey lighten-4 mt-2 mb-0 mx-auto text-left px-4 pb-6 pt-2"  style="height:400px; border: 1px dashed #d0d0d0 !important;" >
                    <v-row class="mb-2">
                        <v-col :cols="6" class="text-left">
                            <span class="caption primary--text" v-html="keywordUsage"/>
                            <div style="opacity: 0.6; display: inline; vertical-align: middle; margin-left: -12px">
                                <information-tooltip
                                    :title="$t('component_projectCreationWizard_stepKeywords_tooltipUsageTitle')"
                                    :text="tooltipUsageText"
                                    :tooltip-width="380"
                                    :icon-size="18"
                                />
                            </div>
                        </v-col>
                        <v-col :cols="6" class="text-right">
                            <span class="caption primary--text" style="cursor: pointer" @click="clearKeywords">{{ $t('component_projectCreationWizard_stepKeywords_clearList') }}<v-icon small color="primary" class="ml-2">mdi-backspace-outline</v-icon></span>
                        </v-col>
                    </v-row>

                    <div style="overflow: auto" :style="`height: ${this.getProjectCreationTmp.keywordGroups.length > 0 ? '230' : '310'}px; `">
                        <v-chip
                            v-for="keyword in getProjectCreationTmp.keywords.slice(0,maxKeywordDisplay)"
                            :key="keyword"
                            class="ma-1"
                            small
                            :close="getProjectCreationTmp.keywords.length <= maxKeywordDisplay"
                            :color="(isOverQuota ? 'red' : 'primary')"
                            close-icon="mdi-minus-circle"
                            outlined
                            style="background-color: #fff !important;"
                            @click:close="removeKeyword(keyword)"
                        >
                            {{ keyword }}
                        </v-chip>
                        <span class="ml-2" v-if="getProjectCreationTmp.keywords.length >maxKeywordDisplay">{{ `+ ${getProjectCreationTmp.keywords.length - maxKeywordDisplay} ${$t('component_projectCreationWizard_stepKeywords_shortListCount')}` }}</span>
                    </div>


                    <div class="py-4" style="border-top: 1px dashed #d0d0d0 !important;" v-if="this.getProjectCreationTmp.keywordGroups.length > 0">
                        <p class="my-0 font-weight-medium">{{ $t('component_projectCreationWizard_stepKeywords_importedSegment') }}</p>
                        <v-chip
                            v-for="marketSegment in this.getProjectCreationTmp.keywordGroups"
                            :key="marketSegment.name"
                            class="ma-1"
                            small

                            :color="(isOverQuota ? 'red' : 'purple lighten-2')"

                            outlined
                            style="background-color: #efe9f2 !important;"
                        >
                            <v-icon left size="small">
                                mdi-tag
                            </v-icon>
                            {{ marketSegment.name }}
                        </v-chip>
                    </div>

                </div>
            </v-col>
            <v-col :cols="12" class="text-right">
                <v-btn
                    outlined
                    color="primary"
                    @click="suggestKeywords"
                    :disabled="disableSuggestKeyword || isOverQuota || isFullQuota"
                >
                    <v-icon style="margin-left: -10px; margin-right: 10px">mdi-auto-fix</v-icon>

                    {{ $t('component_projectCreationWizard_stepKeywords_suggestKeyword') }}
                </v-btn>
            </v-col>
            <v-col :cols="12" style="margin-top: -50px">


                <div
                    class="mt-0 mx-auto"
                    style="max-width: 440px"
                >
                    <div v-if="isOverQuota">
                        <p class="text-body-2 mb-0 rounded-xl py-1" :class="['red lighten-5', 'red--text']">
                            {{ $t('component_projectCreationWizard_stepKeywords_error_overQuotaKeywordsLabel') }}
                        </p>
                    </div>
                    <div v-else-if="isFullQuota">
                        <p class="text-body-2 mb-0 rounded-xl py-1" :class="['orange lighten-5', 'orange--text']">
                            {{ $t('component_projectCreationWizard_stepKeywords_error_fullQuotaKeywordsLabel') }}
                        </p>
                    </div>
                    <div v-else-if="errorSuggest">
                        <p class="text-body-2 mb-0 rounded-xl py-1" :class="['orange lighten-5', 'orange--text']">
                            {{ $t('component_projectCreationWizard_stepKeywords_error_suggestError') }}
                        </p>
                    </div>
                    <div v-else-if="errorNoLanguage">
                        <p class="text-body-2 mb-0 rounded-xl py-1" :class="['orange lighten-5', 'orange--text']">
                            {{ $t('component_projectCreationWizard_stepKeywords_error_location') }}
                        </p>
                    </div>
                    <div v-else-if="errorNoSearchEngine">
                        <p class="text-body-2 mb-0 rounded-xl py-1" :class="['orange lighten-5', 'orange--text']">
                            {{ $t('component_projectCreationWizard_stepKeywords_error_searchEngine') }}
                        </p>
                    </div>
                    <div v-else-if="errorNoWebsite">
                        <p class="text-body-2 mb-0 rounded-xl py-1" :class="['orange lighten-5', 'orange--text']">
                            {{ $t('component_projectCreationWizard_stepKeywords_error_webSiteBadUrlFormat') }}
                        </p>
                    </div>

                </div>

            </v-col>
        </v-row>

    </div>
</template>

<script>

import {mapGetters} from "vuex";
import LabelMessage from "@/components/yooda-components/LabelMessage.vue";
import InformationTooltip from "@/components/yooda-components/InformationTooltip.vue";
import apiCalls from "@/api/axios/configuration/project";
import ImportCsvKeyword from "@/components/yooda-components/project/ImportCsvKeyword.vue";

export default {
    name: "ProjectCreationWizardStepKeywords",
    data(){
        return{
            inputAddKeywords:null,
            disableSuggestKeyword:false,
            suggestLoader:false,
            errorSuggest:false,
            errorNoLanguage:false,
            errorNoSearchEngine:false,
            errorNoWebsite:false,
            maxKeywordDisplay:101
        }
    },
    components:{
        InformationTooltip,
        LabelMessage,
        ImportCsvKeyword,
    },
    computed:{

        ...mapGetters({
            maxRequestCount:"customersManager/getMaxRequestCount",
            additionalRequestCount:"customersManager/getAdditionalRequestCount",
            activeSubscription:"customersManager/getActiveSubscription",
        }),

        maxKeyword(){
            return  (this.maxRequestCount()+this.additionalRequestCount()) - (this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctKeywordCount*project.distinctSearchGeolocationCount),0) : 0)
        },

        isOverQuota(){
            return (this.getProjectCreationTmp.keywords.length * this.getProjectCreationTmp.locations.length) > this.maxKeyword
        },
        isFullQuota(){
            return (this.getProjectCreationTmp.keywords.length * this.getProjectCreationTmp.locations.length) === this.maxKeyword
        },
        keywordUsage(){
            return this.$t('component_projectCreationWizard_stepKeywords_keywordsRemaining').replace('[*KEYWORD-COUNT*]',(this.getProjectCreationTmp.keywords.length * this.getProjectCreationTmp.locations.length)).replace('[*KEYWORD-MAX*]',this.maxKeyword)
        } ,
        tooltipUsageText(){
            return this.$t('component_projectCreationWizard_stepKeywords_tooltipUsageText')
                .replace('[*KEYWORD-TOTAL-COUNT*]',(this.getProjectCreationTmp.keywords.length * this.getProjectCreationTmp.locations.length))
                .replace('[*LOCATION-COUNT*]',this.getProjectCreationTmp.locations.length)
                .replace('[*KEYWORD-COUNT*]',this.getProjectCreationTmp.keywords.length)
                .replace('[*QUOTA-TOTAL*]',(this.maxRequestCount()+this.additionalRequestCount()))
                .replace('[*PROJECT-USAGE-COUNT*]',(this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctKeywordCount*project.distinctSearchGeolocationCount),0) : 0))
                .replace('[*REMAINING-COUNT*]',(this.maxKeyword - (this.getProjectCreationTmp.keywords.length * this.getProjectCreationTmp.locations.length)))
        }
    },
    methods:{
        pasteKeyword(){
            setTimeout(()=>{
                this.addKeywords()
            }, 200);
        },
        addKeywords(){
            let selectedKeywordListTmp = this.getProjectCreationTmp.keywords
            this.explodeStringToArray(this.inputAddKeywords).forEach((value)=>{
                if(value.trim() !== ''){
                    selectedKeywordListTmp.push(this.cleanKeywordInput(value))
                }
            })
            let uniqueKeywords = [...new Set(selectedKeywordListTmp)];
            this.setProjectCreationTmpValue('keywords',uniqueKeywords)
            this.inputAddKeywords = null
            if(this.getProjectCreationTmp.keywords.length>0){ this.$emit('keywordIsValid') }
        },
        removeKeyword (keywordToDelete) {
            let selectedKeywordListTmp = this.getProjectCreationTmp.keywords
            selectedKeywordListTmp.splice(selectedKeywordListTmp.indexOf(keywordToDelete), 1)
            this.setProjectCreationTmpValue('keywords',selectedKeywordListTmp)

            let newKeywordGroups = []
            this.getProjectCreationTmp.keywordGroups.forEach(data=>{
                newKeywordGroups.push({
                    name:data.name,
                    keywords:data.keywords.filter(keyword=>{
                        return keyword.string !== keywordToDelete
                    })
                })
            })
            this.setProjectCreationTmpValue('keywordGroups',newKeywordGroups)

            if(selectedKeywordListTmp.length === 0){ this.$emit('keywordIsInvalid') }
            if(this.getProjectCreationTmp.keywords.length>0){ this.$emit('keywordIsValid') }
        },
        clearKeywords(){
            this.setProjectCreationTmpValue('keywords',[])
            this.setProjectCreationTmpValue('keywordGroups',[])

            this.$emit('keywordIsInvalid')
        },

        onImportCsv(csvData){
            this.setProjectCreationTmpValue('keywords',csvData.keywords.map((keyword)=>{return keyword.string}))
            let keywordsGroup = csvData.segments.map((segment)=>{return { name :segment.name,keywords:[] }})
            csvData.csvData.forEach(data=>{
                let findedIndex = keywordsGroup.findIndex(element => element.name === data.segment)
                if(findedIndex >=0){
                    keywordsGroup[findedIndex].keywords.push({string:data.keyword})
                }
            })
            this.setProjectCreationTmpValue('keywordGroups',keywordsGroup)
            if(this.getProjectCreationTmp.keywords.length>0){ this.$emit('keywordIsValid') }
        },

        suggestKeywords(){
            if(this.getProjectCreationTmp.websiteUrl){
                if(this.getProjectCreationTmp.searchEngine){
                    if(this.getProjectCreationTmp.language){
                        this.suggestLoader = true
                        this.errorSuggest = false
                        this.errorNoLanguage = false
                        this.errorNoSearchEngine = false
                        this.errorNoWebsite = false

                        const searchGeolocation = this.getProjectCreationTmp.searchEngine.searchGeolocations.find(searchGeolocation => {
                            return searchGeolocation.targetType === "country"
                        })

                        apiCalls.getSuggestedKeywordByDomainName(this.getProjectCreationTmp.websiteUrl, searchGeolocation.criteriaId, this.getProjectCreationTmp.language.code).then((result)=>{
                            if(result.content){
                                let suggestedKeywords = result.content
                                let uniqueKeywords = [...new Set(this.getProjectCreationTmp.keywords.concat(suggestedKeywords.map(data=>{ return data.searchExpression })))];
                                if((uniqueKeywords.length * this.getProjectCreationTmp.locations.length) > this.maxKeyword){
                                    let suggestedCountMax = Math.floor(this.maxKeyword / this.getProjectCreationTmp.locations.length)
                                    uniqueKeywords = uniqueKeywords.slice(0,suggestedCountMax)
                                }
                                this.setProjectCreationTmpValue('keywords',uniqueKeywords)
                                this.disableSuggestKeyword = true
                                if(this.getProjectCreationTmp.keywords.length>0){ this.$emit('keywordIsValid') }
                            }
                            else{ this.errorSuggest = true }
                        })
                        .catch(()=>{ this.errorSuggest = true })
                        .finally(()=>{ this.suggestLoader = false })
                    }
                    else{ this.errorNoLanguage = true }
                }
                else{ this.errorNoSearchEngine = true }
            }else{ this.errorNoWebsite = true }
        },
    },

}
</script>

<style scoped>

</style>
