<template>
    <div>
        <h3 class="text-h4 font-weight-bold mb-2">{{ $t('component_projectCreationWizard_stepAnalytics_title') }}</h3>
        <p style="max-width: 580px; margin: auto">{{ $t('component_projectCreationWizard_stepAnalytics_subtitle') }}</p>

        <div style="max-width: 800px; margin: auto">
            <analytics-project-configuration
                :can-delete-account="false"
                :from-create-project="true"
                ref="analyticsCreateProjectConfiguration"
            />

        </div>


    </div>
</template>

<script>
import AnalyticsProjectConfiguration from "@/components/yooda-components/analytics/AnalyticsProjectConfiguration.vue";

export default {
    data(){
        return{
            dataSourceTypeSelected:null
        }
    },
    components:{
        AnalyticsProjectConfiguration
    },
    computed:{

    },
    methods:{
        getPropertyConfig(){
            if(this.$refs.analyticsCreateProjectConfiguration){
                return this.$refs.analyticsCreateProjectConfiguration.getPropertyConfig()
            }
            else{
                return false
            }
        },

    },
    beforeMount() {
        if(this.$route.query.refreshTokenAnalytics){
            this.dataSourceTypeSelected = 'google_analytics'
        }
    }
}
</script>

<style scoped>

</style>
