<template>


    <div v-if="showLabel">
        <p class="text-body-2 mb-0 rounded-xl py-1" :class="[bgColor, textColor]">
            {{ labelMessage }}
        </p>
    </div>


</template>

<script>
    export default {
        data() {
            return {
                showLabel: false,
                labelMessage: '',
                bgColor:'red lighten-5',
                textColor:'red--text',
            }
        },
        methods: {
            hideLabelMessage(){
                this.showLabel = false
            },
            showLabelMessage(labelMessage, bgColor='red lighten-5', textColor='red--text'){
                this.labelMessage = labelMessage;
                this.bgColor = bgColor
                this.textColor = textColor
                this.showLabel = true
            }

        }

    }
</script>

