<template>
    <v-card class="v-application" width="1400px" style="height:90vh; overflow: hidden" @wheel="handleScroll">
        <v-row class="ma-0" style="height: 100%">
            <v-col :cols="1" class="pa-0">
                <div style="height: 100%" id="wizard-step-progress" class="py-16 d-flex flex-column justify-space-between align-center">
                    <div
                        v-for="i in 6"
                        :key="i"
                        :class="`step-${i} ${(currentStep === i-1 ? 'step-active' : '')} ${(currentStep>i-1 ? 'step-done' : '')} ${(i === 1 && step1Error ? 'step-error' : '')} ${(i === 2 && (step2Error || isOverQuota) ? 'step-error' : '')} ${(i === 3 && (step3Error || isOverQuota) ? 'step-error' : '')} `"
                    >
                        <div v-if="currentStep>=i-1"/>
                        <span @click="currentStep=i-1">{{ i }}</span>
                    </div>
                </div>
            </v-col>

            <v-col :cols="11" class="pa-0" id="wizard-container">

                <project-creation-form-step-website
                    class="wizard-step-content"
                    :class="`${(currentStep === 0 ? 'step-active': '')}`"
                    @websiteIsInvalid="checkStep(1,true)"
                    @websiteIsValid="checkStep(1,false)"
                />

                <project-creation-form-step-location
                    class="wizard-step-content"
                    :class="`${(currentStep === 1 ? 'step-active': '')}`"
                    @locationIsInvalid="checkStep(2,true)"
                    @locationIsValid="checkStep(2,false)"
                />

                <project-creation-form-step-keywords
                    class="wizard-step-content"
                    :class="`${(currentStep === 2 ? 'step-active': '')}`"
                    @keywordIsInvalid="checkStep(3,true)"
                    @keywordIsValid="checkStep(3,false)"
                />

                <project-creation-form-step-competitors
                    class="wizard-step-content"
                    :class="`${(currentStep === 3 ? 'step-active': '')}`"
                />

                <project-creation-form-step-analytics
                    ref="stepAnalytics"
                    class="wizard-step-content"
                    :class="`${(currentStep === 4 ? 'step-active': '')}`"
                    v-if="analyticsInitialized"
                />
                <div v-else style="position: relative; min-height: 92px">
                    <div class="v-application text-center" style="top: 50%; position: absolute; left: 50%">
                        <v-progress-circular indeterminate color="primary"/>
                    </div>
                </div>

                <div class="wizard-step-content" :class="`${(currentStep === 5 ? 'step-active': '')}`">
                    <h3 class="text-h3 font-weight-bold mb-16 mt-16 pb-10">{{ $t('component_projectCreationWizard_finish') }}</h3>
                    <v-btn color="primary" :disabled="disableCreateButton" x-large @click="createProject">{{ $t('component_projectCreationWizard_createButton') }}</v-btn>
                </div>

            </v-col>
        </v-row>

        <v-icon id="next-step-button" :class="nextStepButtonClass" @click="currentStep <5 ? currentStep++ : currentStep=5" v-if="currentStep<5">mdi-chevron-double-down</v-icon>
        <v-icon id="close-button" large @click="$emit('close')">mdi-close</v-icon>
<!--        <v-btn id="assistant-button" outlined color="primary" :to="{name:'first_project'}">{{ $t('component_projectCreationWizard_assistantButton') }}</v-btn>-->
    </v-card>

</template>


<script>

import ProjectCreationFormStepWebsite from "@/components/yooda-components/projectForm/ProjectCreationFormStepWebsite.vue";
import ProjectCreationFormStepLocation from "@/components/yooda-components/projectForm/ProjectCreationFormStepLocation.vue";
import ProjectCreationFormStepKeywords from "@/components/yooda-components/projectForm/ProjectCreationFormStepKeywords.vue";
import ProjectCreationFormStepCompetitors from "@/components/yooda-components/projectForm/ProjectCreationFormStepCompetitors.vue";
import ProjectCreationFormStepAnalytics from "@/components/yooda-components/projectForm/ProjectCreationFormStepAnalytics.vue";
import apiCalls from "@/api/axios/configuration/project";
import projectApiCalls from "@/api/axios/configuration/project";
import {mapGetters} from "vuex";
import analyticsApiCalls from "@/api/axios/configuration/analytics";

export default {

    data() {
        return {
            currentStep: 0,
            step1Error:null,
            step2Error:null,
            step3Error:null,
            analyticsInitialized:false
        }
    },
    components: {
        ProjectCreationFormStepWebsite,
        ProjectCreationFormStepLocation,
        ProjectCreationFormStepKeywords,
        ProjectCreationFormStepCompetitors,
        ProjectCreationFormStepAnalytics,
    },
    computed:{
        ...mapGetters({
            maxRequestCount:"customersManager/getMaxRequestCount",
            additionalRequestCount:"customersManager/getAdditionalRequestCount",
        }),

        isOverQuota(){
            return (this.getProjectCreationTmp.locations.length * this.getProjectCreationTmp.keywords.length) > this.maxKeyword
        },
        maxKeyword(){
            return  (this.maxRequestCount()+this.additionalRequestCount()) - (this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctKeywordCount*project.distinctSearchGeolocationCount),0) : 0)
        },

        nextStepButtonClass(){
            if(this.currentStep === 0){
                if(this.step1Error === false){
                    return 'step-go-next'
                }
            }
            if(this.currentStep === 1){
                if(this.step2Error === false){
                    return 'step-go-next'
                }
            }
            if(this.currentStep === 2){
                if(this.step3Error === false){
                    return 'step-go-next'
                }
            }
            if(this.currentStep === 3){ return 'step-go-next' }
            if(this.currentStep === 4){ return 'step-go-next' }
            if(this.currentStep === 5){ return 'step-go-next' }
            return ''

        },
        disableCreateButton(){
            if(
                this.getProjectCreationTmp.websiteUrl &&
                this.getProjectCreationTmp.language &&
                this.getProjectCreationTmp.searchEngine &&
                this.getProjectCreationTmp.locations.length >0 &&
                this.getProjectCreationTmp.keywords.length >0
            ){
                return false
            }

            return true
        }
    },
    methods:{
        handleScroll(e){
            if (e.deltaY > 0){
                if(this.currentStep < 5){
                    this.stepProgress()
                }
            }
            else if (e.deltaY < 0){
                if(this.currentStep >0 ){
                    this.currentStep--
                }
            }
        },
        stepProgress(){
            if(this.currentStep === 0 && this.step1Error === null){this.step1Error = true}
            if(this.currentStep === 1 && this.step2Error === null){this.step2Error = true}
            if(this.currentStep === 2 && this.step3Error === null){this.step3Error = true}

            this.currentStep++
        },

        checkStep(step,status){
            switch (step){
                case 1:
                    this.step1Error = status
                    break;
                case 2:
                    this.step2Error = status
                    break;
                case 3:
                    this.step3Error = status
                    break;
            }
        },
        createProject () {
            const regex = /["([|`_^)\]}{!\\/;,?¤<\t\r\n]/gmi

            let projectConfiguration = {
                url:this.getProjectCreationTmp.websiteUrl,
                name:this.getProjectCreationTmp.websiteUrl,
                rivals:this.getProjectCreationTmp.competitors.map(data=>{ return {url:data} }),
                language:{id:this.getProjectCreationTmp.language.id},
                searchEngine:{id:this.getProjectCreationTmp.searchEngine.id},
                searchGeolocations:this.getProjectCreationTmp.locations.map(data=>{ return {id:data.id} }),
                keywords:this.getProjectCreationTmp.keywords.map(data=>{ return {string:data.replace(regex,'')} }),
                keywordGroups:this.getProjectCreationTmp.keywordGroups,
            }

            this.$vs.loading()
            let promise = apiCalls.createProject(this.$route.params.subscriptionId, projectConfiguration)
            promise.then((response) => {
                localStorage.removeItem('projectWizardTmp')

                let route = '/subscriptions/'+this.$route.params.subscriptionId+'/projects/'+response.content.id+'/dashboard-recommendations'
                let analyticsParam = this.$refs.stepAnalytics.getPropertyConfig()

                if(analyticsParam.dataSourceId && analyticsParam.viewConfiguration){

                    let promise = projectApiCalls.updateProject(response.content.id,{analyticsViewConfiguration:analyticsParam.viewConfiguration, analyticsDataSource:analyticsParam.dataSourceId})
                    promise.then((response)=>{
                        if(response){
                            this.analyticsIdentify()
                            setTimeout(function(){ window.location.href = route}, 1000);
                        }
                    })

                }
                else{
                    setTimeout(function(){ window.location.href = route }, 1000);
                }
            })
        },
    },

    beforeMount() {
        let projectTmp = JSON.parse(localStorage.getItem('projectWizardTmp'))
        if(projectTmp){
            if(projectTmp.websiteUrl){
                this.setProjectCreationTmpValue('websiteUrl',projectTmp.websiteUrl)
                this.currentStep = 1
                this.step1Error = false
            }
            if(projectTmp.searchEngine){
                this.setProjectCreationTmpValue('searchEngine',projectTmp.searchEngine)
            }
            if(projectTmp.language){
                this.setProjectCreationTmpValue('language',projectTmp.language)
            }
            if(projectTmp.locations){
                this.setProjectCreationTmpValue('locations',projectTmp.locations)
                if(projectTmp.locations.length>0){
                    this.step2Error = false
                    this.currentStep = 2
                }

            }
            if(projectTmp.keywords){
                this.setProjectCreationTmpValue('keywords',projectTmp.keywords)
                if(projectTmp.keywords.length>0){
                    this.currentStep = 3
                    this.step3Error = false
                }
            }
            if(projectTmp.keywordGroups){
                this.setProjectCreationTmpValue('keywordGroups',projectTmp.keywordGroups)
            }
            if(projectTmp.competitors){
                this.setProjectCreationTmpValue('competitors',projectTmp.competitors)
                if(projectTmp.competitors.length>0){this.currentStep = 4}
            }

            if(this.currentStep > 1 && this.step1Error === null){this.step1Error = true}
            if(this.currentStep > 2 && this.step2Error === null){this.step2Error = true}
            if(this.currentStep > 3 && this.step3Error === null){this.step3Error = true}

        }
    },

    mounted() {
        this.setDataComponent({
            stateName:'analyticsDataSource',
            promise: analyticsApiCalls.getDataSources(this.$route.params.subscriptionId, null),
            dataFormatter: this.formatDataSource,
            asyncFormatter:true
        }).then((response)=>{
            if(response){
                this.analyticsInitialized = true
            }
        })
    }
}
</script>

<style lang="scss">

#wizard-step-progress{
    div{
        text-align: center;
        position: relative;
        span{
            display: block;
            background-color: #ddd;
            border: 2px solid #ddd;
            color: #fff;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            line-height: 48px;
            font-size: 20px;
            cursor: pointer;
            transition: background-color 0.5s;
            z-index: 99;
            position: relative;
        }
        >div{
            z-index: 8;
            height: 500px;
            width: 4px;
            position: absolute;
            top: -500px;
            left: 22px;
            background-color: $primary;
        }

        &.step-done{
            span{
                background-color: $primary;
                border: 2px solid $primary;
                transition: background-color 0.5s;
            }

        }
        &.step-active{
            span{
                border: 2px solid $primary;
                background-color: #fff;
                color: $primary;
                transition: background-color 0.5s;

                animation: pulse-primary 3s infinite;
            }

            &.step-error{
                span{
                    animation: pulse-error 3s infinite;
                }
            }

        }
        &.step-error{
            span{
                border: 2px solid $danger;
                background-color: $danger;
                color: #fff;
                transition: background-color 0.5s;
            }
        }
        &.step-5{
            &.step-done{
                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    width: 4px;
                    left: 21px;
                    height: 400px;
                    background-color: $primary;
                }
            }
        }
    }
}


#wizard-container{
    display: flex;
    justify-content: center;
    align-items: center;
    .wizard-step-content{
        display: none;
        width: 100%;
        padding-left: 10px;
        padding-right: 88px;
        text-align: center;

        &.step-active{
            display: block;
            animation: slideInEntrance 0.5s 0.1s both;
        }
    }
}

#next-step-button{
    position: absolute;
    bottom: 10px;
    left: calc(50% - 30px);
    font-size: 60px;
    opacity: 0.2;
    transition: opacity 0.4s;
    cursor: pointer;
    animation: bounce 4s infinite;
    &:hover{
        opacity: 0.35;
        transition: opacity 0.4s;
    }

    &.step-go-next{
        color: $primary;
        opacity: 1;
        border-radius: 50%;
        background-color: rgba(0, 143, 232, 0.20);
    }
}

#close-button{
    position: absolute;
    top: 16px;
    right: 26px;
    opacity: 0.5;
    transition: opacity 0.4s;
    &:hover{
        opacity: 0.7;
        transition: opacity 0.4s;
    }
}
#assistant-button{
    position: absolute;
    top: 64px;
    right: 32px;
}



</style>
