<template>
    <div>
        <h3 class="text-h4 font-weight-bold mb-2">{{ $t('component_projectCreationWizard_stepWebSite_title') }}</h3>
        <p>{{ $t('component_projectCreationWizard_stepWebSite_subtitle') }}</p>

        <v-text-field
            v-model="websiteUrl"
            :placeholder="$t('component_projectCreationWizard_stepWebSite_inputPlaceholder')"
            dense
            outlined
            hide-details
            class="rounded-lg mx-auto"
            style="max-width: 400px"
            @blur="cleanWebsiteUrl"
            v-on:keyup.enter="cleanWebsiteUrl"
            v-on:keyup="checkInputWebsiteIsValid"
        />

        <div style="min-height: 26px;max-width: 360px; margin: 12px auto">
            <label-message ref="websiteErrorMessage"  />
        </div>
    </div>
</template>

<script>
import LabelMessage from "@/components/yooda-components/LabelMessage.vue";

export default {
    name: "ProjectCreationWizardStepWebsite",
    data(){
        return{
            websiteUrl:null,
            savedWebsiteUrl:null,
            timeoutId:null,
        }
    },
    components:{
        LabelMessage
    },
    methods:{
        checkInputWebsiteIsValid(){
            clearTimeout(this.timeoutId)
            this.timeoutId = setTimeout(()=>{
                if(this.getDomainNameFromString(this.websiteUrl)){
                    this.cleanWebsiteUrl()
                }
            }, 1200);
        },
        cleanWebsiteUrl(){
            if(this.websiteUrl !== this.savedWebsiteUrl){
                this.savedWebsiteUrl = this.websiteUrl
                this.$refs.websiteErrorMessage.hideLabelMessage()
                this.websiteUrl = this.getDomainNameFromString(this.websiteUrl)
                this.setProjectCreationTmpValue('websiteUrl',this.websiteUrl)
                if(!this.websiteUrl){
                    this.$refs.websiteErrorMessage.showLabelMessage(this.$t('component_projectCreationWizard_stepWebSite_error_badUrlFormat'))
                    this.$emit('websiteIsInvalid')
                }
                else{
                    this.$emit('websiteIsValid')
                }
            }
        },
    },
    mounted(){
        let projectTmp = JSON.parse(localStorage.getItem('projectWizardTmp'))
        if(projectTmp && projectTmp.websiteUrl){
            this.websiteUrl = projectTmp.websiteUrl
            this.savedWebsiteUrl = projectTmp.websiteUrl
        }
    }
}
</script>

<style scoped>

</style>
